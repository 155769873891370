import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'

import { useToaster } from '../../hooks'
import { useFinalizeConsultationMutation } from '../../queries'
import { useBmiMutation } from '../../queries/medical/useBmiMutation'
import { VisitStatus } from '../../services/medical/types'
import { bmiCalculator } from '../../services/utils/bmi'
import { Button, Card, Flex, Input, List, Text } from '../../ui'
import { bmiRequiredSlugs } from '../CartBuilder/TreatmentPlanActions'
import { useTreatmentContext } from '../CartBuilder/TreatmentProvider'
import { usePrescriptionsContext } from '../Prescriptions/PrescriptionsProvider'
import { EndConsultButton } from './EndConsultButton'
import { NoShowConsultButton } from './NoShowConsultButton'
import { usePlatoContext } from './PlatoProvider'
import { StartConsultButton } from './StartConsultButton'

const CONSULTATION_TYPE = 'customer-pick'

export const ActionButtons: FC = () => {
  const { order, status, brand, startedAt, endedAt, isNoShow, markedNoShowAt } =
    usePlatoContext()
  const { error, mutate, isLoading } = useFinalizeConsultationMutation()
  const { clearCurrentPrescriptions, prescriptions } = usePrescriptionsContext()

  const { weight, height, setWeight, setHeight } = useTreatmentContext()

  const { mutate: bmiMutate } = useBmiMutation()

  const hasWeightLossInTreatmentCart = useMemo(
    () =>
      order?.orderItems.some(item =>
        bmiRequiredSlugs.includes(item?.product?.category?.slug),
      ),
    [order],
  )

  const [reason, setReason] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [color, setColor] = useState<'danger' | undefined>(undefined)
  const toast = useToaster()

  const onChange = (text: string) => {
    setReason(text)
    if (errorMessage) {
      setErrorMessage('')
      setColor(undefined)
    }
  }

  useEffect(() => {
    if (!error) return
    const { message } = error as Error
    setErrorMessage(message)
    setColor('danger')
  }, [error])

  if (status !== VisitStatus.Registered) {
    return <></>
  }

  const createPlanHandler = () => {
    let bmi
    if (hasWeightLossInTreatmentCart) {
      if (!(Boolean(+height) && Boolean(+weight))) {
        alert('Please fill the Height and Weight')
        return
      } else {
        bmi = String(bmiCalculator(+weight, +height))

        if (bmi === undefined || isNaN(+bmi)) {
          alert('Invalid Height or Weight values')
          return
        }

        mutate({
          consultationType: CONSULTATION_TYPE,
          orderRef: order.orderRef,
          order,
          reason,
          status: true,
        })

        bmiMutate(
          {
            bmi,
            height,
            weight,
          },
          {
            onSuccess: () => {
              setWeight('')
              setHeight('')
            },
          },
        )
      }
      return
    }
    mutate({
      consultationType: CONSULTATION_TYPE,
      orderRef: order.orderRef,
      order,
      reason,
      status: true,
    })
  }

  const onApprove = () => {
    toast.dismissAll()
    if (brand === 'modules-sg') {
      const shouldApprove = confirm(
        'Are you sure you want to approve this treatment?',
      )
      if (!shouldApprove) {
        return
      }
    }
    if (brand === 'modules-sg' && prescriptions && prescriptions.length === 0) {
      toast.error('Please create prescription before approving treatment')
    } else {
      createPlanHandler()
    }
  }

  return (
    <Card>
      <List>
        <Text isBold>Reason for rejection:</Text>
        <Input
          border-radius="full"
          color={color}
          message={errorMessage}
          onChange={event => onChange(event.target.value)}
        />
        <Flex className="my-4">
          <Button
            className="bg-blue1"
            disabled={isLoading}
            type="button"
            variant="primary"
            onClick={onApprove}
          >
            Approve
          </Button>
          <Button
            className="bg-steel1"
            disabled={isLoading}
            type="button"
            onClick={() => {
              mutate({
                consultationType: CONSULTATION_TYPE,
                orderRef: order.orderRef,
                order,
                reason,
                status: false,
              })
              clearCurrentPrescriptions()
            }}
          >
            Reject
          </Button>
        </Flex>
        {!isNoShow && !markedNoShowAt && <NoShowConsultButton />}

        {markedNoShowAt ? (
          <span className="text-sm">
            No Show Marked at{' '}
            {dayjs(markedNoShowAt).format('MMMM D, YYYY h:mm A')}
          </span>
        ) : null}
        {!startedAt && <StartConsultButton />}
        {startedAt && !endedAt && <EndConsultButton />}
      </List>
    </Card>
  )
}
