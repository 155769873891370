import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'

import { useAuthorizationQuery } from '../../queries'
import { usePrescriptionsQuery } from '../../services'
import {
  MedicalPrescriptionMetadata,
  PrescriptionProduct,
} from '../../services/medical/getMedicalPrescriptions'
import { TreatmentProduct } from '../CartBuilder/TreatmentProvider'
import { usePlatoContext } from '../Plato/PlatoProvider'

export type PrescriptionsContext = {
  addToPrescriptions: (product: PrescriptionProduct) => void
  addTreatmentProductToPrescriptions: (product: TreatmentProduct) => void
  clearCurrentPrescriptions: () => void
  currentPrescriptions: PrescriptionProduct[]
  getPrescription: (
    id: string,
    prescriptionIndex: number,
  ) => MedicalPrescriptionMetadata | undefined
  patchRemarks: (slug: string, remarks: string) => void
  patchQuantity: (slug: string, remarks: string) => void
  prescriptions: MedicalPrescriptionMetadata[] | undefined
  removeFromPrescriptions: (slug: string) => void
  addConsultationProductToPrescriptions: (product: PrescriptionProduct) => void
  patchProductName: (slug: string, updatedName: string) => void
}

export type PrescriptionsProviderProps = PropsWithChildren<{}>

const prescriptionsContext = createContext<PrescriptionsContext | undefined>(
  undefined,
)

export const PrescriptionsProvider: FC<PrescriptionsProviderProps> = ({
  children,
}) => {
  const { brand } = usePlatoContext()
  const { metadata } = useAuthorizationQuery()
  const { prescriptions } = usePrescriptionsQuery()
  const [currentPrescriptions, setPrescriptions] = useState<
    PrescriptionProduct[]
  >([])

  const addToPrescriptions = (product: PrescriptionProduct) => {
    const notAllowedCategories = ['consultation', 'rx-booster']
    if (
      !product.requiresPrescription ||
      notAllowedCategories.indexOf(product.category?.slug || '') > -1
    ) {
      return
    }

    setPrescriptions(currPrescriptions => [
      ...currPrescriptions.filter(
        currProduct => currProduct.name !== product.name,
      ),
      product,
    ])
  }

  const addTreatmentProductToPrescriptions = useCallback(
    (product: TreatmentProduct) => {
      const days = 30
      // The WL Subscription Product Validity is 3 months
      const WLSubscriptionProductValidity = days * 3

      // if product category is WL and interval is  day then quantity should be based on
      // 90 / recurring period
      const WLCategoryWithDaysInterval =
        product.category.slug?.toLowerCase() === 'weight-loss' &&
        product.plan.recurring.interval === 'day'

      let quantityPrescribed = WLCategoryWithDaysInterval
        ? Math.floor(
            WLSubscriptionProductValidity / product.plan.recurring.period!,
          )
        : (product.plan.recurring.period ?? -1) > 0
        ? 6
        : 1
      if (brand === 'modules-sg') {
        quantityPrescribed = product.plan?.recurring?.period || 1
      }

      setPrescriptions([
        ...currentPrescriptions,
        {
          instructions: {
            frequency: `${product.instructions.dosage ?? ''} 
              ${product.quantity ?? 'NA'}`,
            remarks: `${product.instructions.description || ''} ${
              product.instructions.dosage ?? ''
            }`,
            quantityPrescribed: `${quantityPrescribed} ${
              product.instructions.dosage ?? ''
            }`,
          },
          isSubscription: !!product.plan.recurring.period,
          name: product.name,
          slug: product.slug,
          status: 'draft',
          refProductId: Number(product?.refId),
        },
      ])
    },
    [currentPrescriptions, brand],
  )

  const addConsultationProductToPrescriptions = useCallback(
    (product: PrescriptionProduct) => {
      setPrescriptions(currPrescriptions => [
        ...currPrescriptions.filter(
          currProduct => currProduct.slug !== product.slug,
        ),
        product,
      ])
    },
    [],
  )

  const removeFromPrescriptions = useCallback(
    (slug: string) => {
      setPrescriptions(
        currentPrescriptions.filter(prescription => prescription.slug !== slug),
      )
    },
    [currentPrescriptions],
  )

  const patchRemarks = useCallback(
    (slug: string, remarks: string) => {
      const copy = [...currentPrescriptions]

      const itemIndex = copy.findIndex(
        prescription => prescription.slug === slug,
      )

      const item = copy.filter(prescription => prescription.slug === slug)

      item[0].instructions.remarks = remarks

      copy.splice(itemIndex, 1, item?.[0])
      setPrescriptions(copy)
    },
    [currentPrescriptions],
  )

  const patchQuantity = useCallback(
    (slug: string, quantity: string) => {
      const copy = [...currentPrescriptions]

      const itemIndex = copy.findIndex(
        prescription => prescription.slug === slug,
      )
      const item = copy.filter(prescription => prescription.slug === slug)
      item[0].instructions.quantityPrescribed = quantity

      copy.splice(itemIndex, 1, item?.[0])
      setPrescriptions(copy)
    },
    [currentPrescriptions],
  )

  const patchProductName = useCallback(
    (slug: string, updatedName: string) => {
      const copy = [...currentPrescriptions]

      const itemIndex = copy.findIndex(
        prescription => prescription.slug === slug,
      )

      const item = copy.filter(prescription => prescription.slug === slug)

      item[0].name = updatedName

      copy.splice(itemIndex, 1, item?.[0])
      setPrescriptions(copy)
    },
    [currentPrescriptions],
  )

  const clearCurrentPrescriptions = () => {
    setPrescriptions([])
  }

  const getPrescription = (id: string, prescriptionIndex: number) => {
    return prescriptions?.prescriptions.filter(
      (prescription, index) =>
        prescription.id === id && prescriptionIndex === index,
    )?.[0]
  }

  if (!metadata) {
    return <></>
  }

  return (
    <prescriptionsContext.Provider
      value={{
        addToPrescriptions,
        addTreatmentProductToPrescriptions,
        clearCurrentPrescriptions,
        currentPrescriptions,
        getPrescription,
        patchRemarks,
        patchQuantity,
        prescriptions: prescriptions?.prescriptions ?? [],
        removeFromPrescriptions,
        addConsultationProductToPrescriptions,
        patchProductName,
      }}
    >
      {children}
    </prescriptionsContext.Provider>
  )
}

export const usePrescriptionsContext = (): PrescriptionsContext => {
  const context = useContext(prescriptionsContext)
  if (!context) {
    throw new Error(
      'usePrescriptionsContext must be used within PrescriptionsProvider',
    )
  }

  return context
}
